import { createRouter, createWebHistory } from "@ionic/vue-router";
import Layout from "@/Layout.vue";
import store from "@/store";

import { RouteRecordRaw } from "vue-router";

import LoginView from "@/views/Login.vue";
import ScannerView from "@/views/Scanner.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "",
        redirect: "/scanner",
      },
      {
        name: "login",
        path: "/login",
        component: LoginView,
      },
      {
        name: "scanner",
        path: "/scanner",
        component: ScannerView,
        beforeEnter: (to, from, next) => {
          if (store.getters["user/user"]) {
            next();
          } else {
            next({ path: "/login" });
          }
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
