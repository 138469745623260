import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

import jobs from "./jobs";
import api from "./api";
import user from "./user";

const store = createStore({
  modules: {
    jobs: jobs,
    api: api,
    user: user,
  },
  plugins: [
    createPersistedState({
      paths: ["jobs.jobs", "user.user"],
    }),
  ],
});

export default store;
