import axios from "axios";
import axiosRetry from "axios-retry";
import api from "./constants";

import { ActionTree } from "vuex";
import { State } from "./state";

import { ERR, ApiError, ClientError } from "../../utils/error";
import { Component } from "../../utils/types";

const actions = <ActionTree<State, any>>{
  async request({ dispatch, state }, { method, path, data }) {
    dispatch("loading", path);

    if (!api.host) {
      throw new ApiError(ERR.NETWORK_RESPONSE, null, "Missing api host");
    }

    return new Promise((resolve, reject) => {
      if (state.request) {
        state.request.cancel();
      }

      state.request = axios.CancelToken.source();

      axiosRetry(axios, {
        retries: api.retries.default,
        retryDelay: axiosRetry.exponentialDelay,
      });

      axios({
        method,
        url: api.host + (api.port ? `:${api.port}` : "") + path,
        data: data || {},
        cancelToken: state.request.token,
        withCredentials: false,
        headers: {
          "Content-Type": "application/json",
        },
        timeout: api.timeout.decode ? api.timeout.decode : api.timeout.default,
      })
        .then((response) => {
          if (response.data) {
            resolve(response.data);
          } else {
            reject(new ApiError(ERR.NETWORK_RESPONSE));
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }

          let code: string = ERR.NETWORK_REQUEST;
          let message = "";

          const data = error.response?.data || null;

          if (data?.code) {
            code = data.code;
          }

          if (data?.message) {
            message = data.message;
          }

          if (data?.errors?.length) {
            code = data.errors[0].code;
            message = data.errors[0].message;
          }

          reject(new ClientError(code, message));
        })
        .finally(() => {
          dispatch("loading", false);
        });
    });
  },
  login({ commit }, { username }) {
    return new Promise((resolve) => {
      const user = {
        username: username,
      };
      commit("user/user", user, { root: true });

      resolve(true);
    });
  },
  getJob({ dispatch }, job) {
    return new Promise((resolve) => {
      dispatch("request", {
        method: "get",
        path: api.paths.job + "?id=" + job,
      })
        .then((data) => {
          if (data.ok) {
            resolve(data.data);
          } else {
            console.log("STATIC");
            resolve(api.static.job);
          }
        })
        .catch((err) => {
          console.log(err);
          resolve(api.static.job);
        });
    });
  },
  setItem({ dispatch }, params) {
    return new Promise((resolve, reject) => {
      const data = {
        job: params.job.id,
        item: {
          id: params.item.id,
          user: params.user,
          date: params.date,
          components: params.item.components.map((component: Component) => {
            return {
              id: component.id,
              charge: component.charge,
            };
          }),
        },
      };

      dispatch("request", {
        method: "post",
        path: api.paths.item,
        data,
      })
        .then((count) => {
          resolve(count);
        })
        .catch(reject);
    });
  },
  count({ dispatch }, job) {
    return new Promise((resolve, reject) => {
      dispatch("request", {
        method: "get",
        path: api.paths.count + "?id=" + job,
      })
        .then((data) => {
          if (data.ok) {
            resolve(data.count);
          }
        })
        .catch(reject);
    });
  },
  loading({ commit }, loading) {
    commit("loading", loading);
  },
  cancel({ dispatch, state }) {
    if (state.request) {
      state.request.cancel();
    }

    dispatch("loading", false);
  },
};

export default actions;
