import { ApiError } from "../../utils/error";
import { CancelTokenSource } from "axios";

export interface Auth {
  expires: string;
  token: string;
}

export class State {
  loading: string | boolean = false;
  error: ApiError | null = null;
  request: CancelTokenSource | null = null;
  auth: Auth | null = null;
}

export default new State();
