export default {
  host: "https://hydac.projects.admon.de",
  port: null,
  timeout: {
    default: 3000,
    decode: 5000,
  },
  retries: {
    default: 3,
    auth: 5,
  },
  paths: {
    job: "/api/job",
    item: "/api/item",
    count: "/api/count",
  },
  static: {
    job: {
      id: "18284399",
      amount: 100,
      product: {
        id: "3247177",
        name: "Blase zweiteilig",
      },
      components: [
        {
          id: "21505499",
          name: "Blase Oberteil",
          product: "3247177",
        },
        {
          id: "31245434",
          name: "Blase Unterteil",
          product: "3247177",
        },
      ],
    },
  },
};
