import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-41c10bbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "button-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!

  return (_openBlock(), _createElementBlock("span", null, [
    (_ctx.variant !== 'fab')
      ? (_openBlock(), _createBlock(_component_ion_button, {
          key: 0,
          type: _ctx.type,
          color: _ctx.color,
          disabled: _ctx.disabled || _ctx.loading,
          size: _ctx.size,
          expand: _ctx.expand,
          href: _ctx.href,
          shape: _ctx.shape,
          strong: _ctx.strong,
          class: _normalizeClass({ shadow: _ctx.shadow })
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              (_ctx.loading)
                ? (_openBlock(), _createBlock(_component_Loading, {
                    key: 0,
                    inline: "",
                    small: ""
                  }))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ])
          ]),
          _: 3
        }, 8, ["type", "color", "disabled", "size", "expand", "href", "shape", "strong", "class"]))
      : _createCommentVNode("", true),
    (_ctx.variant === 'fab')
      ? (_openBlock(), _createBlock(_component_ion_fab_button, {
          key: 1,
          size: _ctx.size,
          color: _ctx.color,
          href: _ctx.href
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true)
          ]),
          _: 3
        }, 8, ["size", "color", "href"]))
      : _createCommentVNode("", true)
  ]))
}