
import { defineComponent } from "vue";
import { RouterLink } from "vue-router";
import { personOutline, logOut } from "ionicons/icons";
import { mapActions, mapGetters } from "vuex";
import Icon from "./Icon.vue";

export default defineComponent({
  name: "UserItem",
  components: {
    Icon,
    RouterLink,
  },
  setup() {
    return {
      personOutline,
      logOut,
    };
  },
  computed: {
    ...mapGetters("user", {
      user: "user",
    }),
  },
  methods: {
    ...mapActions("api", {
      logout: "logout",
    }),
  },
});
