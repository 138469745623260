import { GetterTree, MutationTree, ActionTree } from "vuex";
import { Job, Product, Item, Component } from "../../utils/types";

class State {
  jobs: Array<Job> = [];
}

const getters = <GetterTree<State, any>>{
  job: (state) => state.jobs[state.jobs.length - 1],
  item: (state) => {
    const item =
      state.jobs[state.jobs.length - 1].items[
        state.jobs[state.jobs.length - 1].items.length - 1
      ];

    if (!item) {
      return;
    }

    return item;
  },
};

const actions = <ActionTree<State, any>>{
  setJob({ commit }, params) {
    const job: Job = {
      id: params.id,
      count: 0,
      amount: params.amount,
      product: params.product,
      items: [],
    };

    commit("job", job);
  },
  setCount({ commit, state }, count) {
    commit("count", count);
  },
  addItem({ commit, state }) {
    const job = state.jobs[state.jobs.length - 1];

    const item: Item = {
      id: null,
      components: job.product.components.map((item) => {
        return {
          name: item.name,
          id: item.id,
          charge: null,
        } as Component;
      }),
    };

    commit("item", item);
  },
  addComponent({ commit, dispatch, state }, params) {
    const job = state.jobs[state.jobs.length - 1];
    const item = job.items[job.items.length - 1];
    const component = item.components?.find(
      (c) => Number(c.id) === Number(params.id)
    );

    if (component) {
      component.charge = params.charge;
      if (params.serial) {
        item.id = params.serial;
      }
    }

    if (component) {
      commit("component", { item, component });
    }

    if (item.components?.find((c) => !c.charge)) {
      return;
    }

    console.log(params.user, params.date);

    dispatch(
      "api/setItem",
      {
        job,
        item,
        user: params.user,
        date: params.date,
      },
      { root: true }
    );
  },
  clear({ commit }) {
    commit("clear");
  },
};

const mutations = <MutationTree<State>>{
  job(state, job: Job) {
    state.jobs.push(job);
  },

  clear(state) {
    state.jobs = [];
  },

  replace(state, job: Job) {
    state.jobs[state.jobs.length - 1] = job;
  },

  count(state, count: number) {
    state.jobs[state.jobs.length - 1].count = count;
  },

  item(state, item: Item) {
    state.jobs[state.jobs.length - 1].items.push(item);
  },

  component(state, { item, component }) {
    const index = item.components.findIndex(
      (comp: Component) => component.id === comp.id
    );

    if (index > -1) {
      item.components[index] = component;
    }
  },
};

export default {
  namespaced: true,
  state: new State(),
  getters,
  actions,
  mutations,
};
