
import { defineComponent } from "vue";
import { IonApp, IonRouterOutlet, modalController } from "@ionic/vue";
import Sound from "./components/Sound.vue";
import type HTMLIonModalElement from "@ionic/core/dist/types/components";
import { SplashScreen } from "@capacitor/splash-screen";

export default defineComponent({
  name: "App",
  components: {
    Sound,
    IonApp,
    IonRouterOutlet,
  },
  data() {
    return {
      modalCtrl: modalController,
      modal: null as HTMLIonModalElement | null,
    };
  },
  mounted() {
    setTimeout(() => {
      SplashScreen.hide();
    }, 4000);
  },
});
