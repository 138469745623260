import router from "../../router";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { JWTPayload } from "jose";

export interface Message {
  title?: string;
  message: string;
  color?: "danger" | "success";
}

export interface Role {
  role: "DEBUG" | "COPY" | "SHARE" | "PRINT";
  enabled: boolean;
}

export interface User {
  username: string;
  roles: Role[];
}

export interface UserToken extends JWTPayload {
  name?: string;
  preferred_username?: string;
  given_name?: string;
  family_name?: string;
  email?: string;
}

export interface PasswordRequest {
  username: string;
  code: string;
}

class State {
  user: User | null = null;
  messages: Array<Message> = [];
  passwordRequest: PasswordRequest | null = null;
  sound: string | null = null;
}

const getters = <GetterTree<State, any>>{
  user: (state: State) => state.user,
  sound: (state: State) => state.sound,
  messages: (state: State) => state.messages,
  message: (state: State) => (state.messages.length ? state.messages[0] : null),
  passwordRequest: (state: State) => state.passwordRequest,
  role: (state: State) => (role: string) => {
    return state.user?.roles.find((item) => item.role === role && item.enabled);
  },
};

const actions = <ActionTree<State, any>>{
  user({ commit }, user: User) {
    commit("user", user);
  },
  verify({ commit }) {
    commit("verify");
  },
  passwordRequest({ commit }, opts) {
    commit("passwordRequest", opts);
  },
  message({ commit }, message: Message) {
    commit("message", message);
  },
  logout({ commit, dispatch }) {
    commit("user", null);
    dispatch("message", {
      title: "Erfolgreich ausgeloggt",
      message: "Sie wurden erfolgreich abgemeldet",
      color: "success",
    });
    router.push("/login");
  },
  feedback({ commit }, type) {
    commit("sound", type);
    setTimeout(() => {
      commit("sound", null);
    }, 500);
  },
};

const mutations = <MutationTree<State>>{
  user(state: State, user: User) {
    state.user = user;
  },
  sound(state: State, sound: string | null) {
    state.sound = sound;
  },
  verify(state: State) {
    if (!state.user) {
      return;
    }
  },
  message(state: State, message: Message) {
    state.messages.unshift(message);
  },
  passwordRequest(state: State, opts) {
    if (opts) {
      if (!state.passwordRequest) {
        state.passwordRequest = {
          username: opts.username || "",
          code: opts.code || "",
        };
      } else {
        if (opts.username) {
          state.passwordRequest.username = opts.username;
        }
        if (opts.code) {
          state.passwordRequest.code = opts.code;
        }
      }
    } else {
      state.passwordRequest = null;
    }
  },
};

export default {
  namespaced: true,
  state: new State(),
  getters,
  mutations,
  actions,
};
