import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Login = _resolveComponent("Login", true)!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, { class: "ion-padding" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card, { class: "ion-padding login-card" }, {
        default: _withCtx(() => [
          _createVNode(_component_Login)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}