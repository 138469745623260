
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { IonTabBar, IonTabButton, IonLabel } from "@ionic/vue";
import { addCircleOutline } from "ionicons/icons";
import Icon from "./Icon.vue";

export default defineComponent({
  name: "TabsLayout",
  components: {
    IonLabel,
    IonTabBar,
    IonTabButton,
    Icon,
  },
  setup() {
    return {
      navigation: false,
      addCircleOutline,
    };
  },
  computed: {
    ...mapGetters("user", {
      user: "user",
    }),
  },
});
