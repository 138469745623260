import { GetterTree } from "vuex";
import type { State } from "./state";

const getters = <GetterTree<State, any>>{
  loading: (state: State) => state.loading,
  error: (state: State) => state.error,
  auth: (state: State) => state.auth,
};

export default getters;
