import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { IonicVue } from "@ionic/vue";
import { addIcons } from "ionicons";

addIcons({
  hydac: require("./assets/img/logo-hydac.svg"),
});

/* Core CSS required for Ionic components to work properly */
import "@ionic/vue/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/vue/css/normalize.css";
import "@ionic/vue/css/structure.css";
import "@ionic/vue/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/vue/css/padding.css";
import "@ionic/vue/css/float-elements.css";
import "@ionic/vue/css/text-alignment.css";
import "@ionic/vue/css/text-transformation.css";
import "@ionic/vue/css/flex-utils.css";
import "@ionic/vue/css/display.css";

/* Theme */
import "./theme/body.css";
import "./theme/variables.css";
import "./theme/fonts.css";
import "./theme/default.css";
import "./theme/text.css";
import "./theme/form.css";
import "./theme/modal.css";
import "./theme/popover.css";
import "./theme/toast.css";
import "./theme/animations.css";

const app = createApp(App).use(IonicVue).use(router).use(store);

router.isReady().then(() => {
  app.mount("#app");
});
