
import clsx from "clsx";
import { defineComponent, Transition } from "vue";
import { IonPage, IonContent, IonIcon, toastController } from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Capacitor } from "@capacitor/core";
import type HTMLIonToastElement from "@ionic/core/dist/types/components";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";

import { BrowserMultiFormatReader, Exception } from "@zxing/library";

import Job from "../components/Job.vue";
import Items from "../components/Items.vue";
import Label from "../components/Label.vue";

export default defineComponent({
  name: "ScannerView",
  components: { IonPage, IonContent, IonIcon, Job, Items, Label, Transition },
  setup() {
    return {
      checkmarkCircleOutline,
      closeCircleOutline,
    };
  },
  data() {
    return {
      overlayVisible: false,
      toast: null as HTMLIonToastElement | null,
      toastCtrl: toastController,
      isPluginAvailable: Capacitor.isPluginAvailable("Camera"),
      webReader: new BrowserMultiFormatReader(),
      webReaderCode: null,
      flash: {
        count: 0,
        type: "success",
      },
      check: 0,
    };
  },
  ionViewDidEnter() {
    this.openScanner();
    this.check = setInterval(() => {
      if (this.job) {
        this.count(this.job.id).then((count) => {
          this.setCount(count);
        });
      }
    }, 3000);
  },
  ionViewWillLeave() {
    if (this.toast) {
      this.toastCtrl.dismiss(null, "cancel");
      this.toast = null;
    }

    this.closeScanner();
    clearInterval(this.check);
  },
  computed: {
    ...mapGetters("user", {
      user: "user",
    }),
    ...mapGetters("jobs", {
      job: "job",
    }),
  },
  methods: {
    clsx,
    ...mapActions("user", {
      message: "message",
      feedback: "feedback",
    }),
    ...mapActions("api", {
      getJob: "getJob",
      count: "count",
    }),
    ...mapActions("jobs", {
      setJob: "setJob",
      setCount: "setCount",
      addItem: "addItem",
      addComponent: "addComponent",
    }),
    success() {
      this.flash.type = "success";
      this.flash.count = this.flash.count + 1;
      this.feedback(this.flash.type);
    },
    warning() {
      this.flash.type = "warning";
      this.flash.count = this.flash.count + 1;
      this.feedback(this.flash.type);
    },
    async openScanner() {
      this.showOverlay();
      this.hideBackground();

      try {
        if (this.isPluginAvailable) {
          await this.checkPermission();

          const data = await BarcodeScanner.startScan();
          if (data.hasContent && data.content) {
            this.handleScan(data.content);
          }
        } else {
          this.webReader = new BrowserMultiFormatReader();
          const video: HTMLVideoElement =
            (this.$refs?.video as HTMLVideoElement) || new HTMLVideoElement();
          const isMediaStreamAPISupported =
            navigator &&
            navigator.mediaDevices &&
            "enumerateDevices" in navigator.mediaDevices;

          if (!isMediaStreamAPISupported) {
            throw new Exception("Media Stream API is not supported");
          }

          if (this.$refs?.video) {
            this.webReader
              .decodeFromVideoDevice(null, video, (result) => {
                if (result) {
                  this.handleScan(result.getText());
                }
              })
              .catch((reason) => {
                switch (reason.toString()) {
                  case "NotReadableError: Could not start video source":
                    this.showError("1001");
                    break;
                  case "NotAllowedError: Permission denied":
                    this.showError("1002");
                    break;
                  default:
                    this.showError("1000");
                }
              });
          }
        }
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    },
    async handleScan(result: string) {
      if (this.toast) {
        this.toastCtrl.dismiss(null, "cancel");
        this.toast = null;
      }

      try {
        const item = JSON.parse(result);
        if (item.id) {
          if (item.type === "job" && this.job?.id !== item.id) {
            this.getJob(item.id).then((data) => {
              this.setJob({
                id: Number(data.id),
                amount: Number(data.amount),
                count: Number(data.count),
                product: data.product,
              });
              this.addItem();
              this.message({
                title: "Auftrag erfasst",
                message: item.id,
                color: "success",
              });
              this.success();
            });
          } else {
            if (this.job && item.type !== "job") {
              const jobItem = this.job.items[this.job.items.length - 1];

              const component = jobItem.components.find(
                (comp: any) => Number(comp.id) === Number(item.id)
              );

              if (component) {
                if (component.charge && component.charge === item.charge) {
                  console.log("existing");
                } else {
                  this.addComponent({
                    id: item.id,
                    charge: item.charge,
                    serial: item.serial,
                    user: this.user.username,
                    date: new Date(),
                  });
                  this.success();
                }
              }
            }
          }
        } else {
          this.warning();
        }
      } catch (e) {
        this.warning();
      }
    },
    async checkPermission() {
      const status = await BarcodeScanner.checkPermission({ force: true });
      return status.granted;
    },
    closeScanner() {
      this.hideOverlay();
      this.showBackground();

      if (this.isPluginAvailable) {
        try {
          BarcodeScanner.stopScan();
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      } else {
        this.webReader.reset();
      }
    },
    showBackground() {
      if (this.isPluginAvailable) {
        try {
          BarcodeScanner.showBackground();
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      }
    },
    hideBackground() {
      if (this.isPluginAvailable) {
        try {
          BarcodeScanner.hideBackground();
        } catch (error) {
          // eslint-disable-next-line
          console.log(error);
        }
      }
    },
    showOverlay() {
      this.overlayVisible = true;
    },
    hideOverlay() {
      this.overlayVisible = false;
    },
    showError(code: string) {
      switch (code) {
        case "803":
          this.message({
            message:
              "Der gescannte Code wird vom System aktuell nicht unterstützt.",
            color: "danger",
          });
          break;
        case "1000":
        case "1001":
          this.message({
            message:
              "Es steht keine Kamera zur Verfügung. Bitte prüfen Sie die Kameraeinstellungen.",
            color: "warning",
          });
          break;
        case "1002":
          this.message({
            message:
              "Der Zugriff auf die Kamera ist nicht möglich. Bitte prüfen Sie die Berechtigungseinstellungen.",
            color: "warning",
          });
          break;
        default:
          this.message({
            message:
              "Die Verbindung zu Server konnte nicht hergestellt werden.",
            color: "danger",
          });
      }
    },
  },
});
