
import { defineComponent } from "vue";

import ForgotPasswordRequestForm from "./ForgotPasswordRequestForm.vue";

export default defineComponent({
  name: "ResetPassword",
  emits: ["success", "cancel"],
  components: {
    ForgotPasswordRequestForm,
  },
});
