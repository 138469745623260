
import { defineComponent } from "vue";
import { IonTabs, IonRouterOutlet, toastController } from "@ionic/vue";
import Header from "./components/Header.vue";
import Navigation from "./components/Navigation.vue";
import { mapGetters } from "vuex";
import type { Message } from "./store/user";
import type HTMLIonToastElement from "@ionic/core/dist/types/components";

export default defineComponent({
  name: "TabsLayout",
  components: {
    IonTabs,
    IonRouterOutlet,
    Header,
    Navigation,
  },
  data() {
    return {
      toast: null as HTMLIonToastElement | null,
      toastCtrl: toastController,
    };
  },
  computed: {
    ...mapGetters("user", {
      message: "message",
    }),
  },
  methods: {
    async showMessage(msg: Message) {
      this.toast = await this.toastCtrl.create({
        cssClass: "toast",
        header: msg.title || undefined,
        color: msg.color || "dark",
        message: msg.message,
        duration: 1500,
        position: "top",
      });
      return this.toast.present();
    },
  },
  watch: {
    message(msg) {
      this.showMessage(msg);
    },
  },
});
