
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import { IonTitle, IonToolbar, IonHeader, popoverController } from "@ionic/vue";
import User from "./User.vue";
import Login from "./Login.vue";
import { personOutline, logIn, logOut } from "ionicons/icons";

export default defineComponent({
  name: "Header",
  setup() {
    return {
      personOutline,
      logIn,
      logOut,
    };
  },
  components: {
    IonTitle,
    IonToolbar,
    IonHeader,
    User,
  },
  computed: {
    ...mapGetters("api", {
      loading: "loading",
      error: "error",
    }),
    ...mapGetters("user", {
      user: "user",
    }),
  },
  methods: {
    ...mapActions("api", {
      logout: "logout",
    }),
    async showLogin(ev: MouseEvent) {
      const popover = await popoverController.create({
        component: Login,
        cssClass: "popover",
        animated: true,
        event: ev,
      });
      return popover.present();
    },
  },
});
