
import { defineComponent } from "vue";
import { IonText } from "@ionic/vue";
import LoginForm from "./LoginForm.vue";
import ResetPasswordForm from "./ResetPasswordForm.vue";

export default defineComponent({
  name: "LoginView",
  components: {
    IonText,
    LoginForm,
    ResetPasswordForm,
  },
  data() {
    return {
      mode: "login",
    };
  },
  methods: {
    selectMode(mode: "login" | "reset-password") {
      this.mode = mode;
    },
  },
});
