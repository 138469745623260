
import { defineComponent, ref } from "vue";
import { mapGetters } from "vuex";
import {
  IonPage,
  IonCard,
} from "@ionic/vue";
import Login from '../components/Login.vue';

export default defineComponent({
  name: "LoginView",
  components: {
    IonPage,
    IonCard,
    Login,
  },
  computed: {
    ...mapGetters("user", {
      user: "user",
    }),
  },
});
