
import { defineComponent } from "vue";
import { IonLabel } from "@ionic/vue";

export default defineComponent({
  name: "Label",
  components: {
    IonLabel,
  },
  props: {
    text: String,
  },
});
