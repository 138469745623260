
import { defineComponent } from "vue";
import { mapGetters, mapActions } from "vuex";
import { checkboxOutline, closeOutline } from "ionicons/icons";

import {
  IonCard,
  IonCardHeader,
  IonCardContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from "@ionic/vue";
export default defineComponent({
  name: "Job",
  components: {
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonIcon,
  },
  setup() {
    return {
      checkboxOutline,
      closeOutline,
    };
  },
  computed: {
    ...mapGetters("jobs", {
      job: "job",
      item: "item",
    }),
  },
  methods: {
    ...mapActions("jobs", {
      clear: "clear",
    }),
  },
});
