import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForgotPasswordRequestForm = _resolveComponent("ForgotPasswordRequestForm")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createVNode(_component_ForgotPasswordRequestForm, {
      onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
    })
  ]))
}