
import { Capacitor } from "@capacitor/core";
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { NativeAudio } from "@capacitor-community/native-audio";

export default defineComponent({
  name: "Sound",
  computed: {
    ...mapGetters("user", ["sound"]),
  },
  mounted() {
    let files = {
      success: "success.wav",
      warning: "warning.wav",
    };
    if (Capacitor.getPlatform() === "ios") {
      files = {
        success: "public/assets/sounds/success.wav",
        warning: "public/assets/sounds/warning.wav",
      };
    }
    NativeAudio.preload({
      assetId: "success",
      assetPath: files.success,
      audioChannelNum: 1,
      isUrl: false,
    });
    NativeAudio.preload({
      assetId: "warning",
      assetPath: files.warning,
      audioChannelNum: 1,
      isUrl: false,
    });
  },
  watch: {
    sound(val) {
      if (val) {
        NativeAudio.play({
          assetId: val,
          time: 0,
        });
      }
    },
  },
});
